import { Injectable, Injector } from '@angular/core';
import { Observable, map } from 'rxjs';
import { IUser, IUserAccount } from '../models/user';
import { AuthService } from './auth.service';
import { BaseCrudService } from './base-crud.service';

@Injectable({
  providedIn: 'root'
})
export class ProfileService extends BaseCrudService<IUserAccount> {
  public staffId: string | undefined;

  constructor(injector: Injector, private authService: AuthService) {
    super(injector);
    this.path = '/client/account';
  }

  getAccountInfo(): Observable<IUserAccount> {
    return this.requestService.getJSON(this.path + '/info');
  }

  changePassword(data: { old_password: string; new_password: string }) {
    return this.requestService.postJSON(this.path + '/change_password', { data });
  }

  updateMyInfo(data: IUserAccount) {
    return this.requestService.patchFile<IUserAccount>(this.path + '/info', { data }).pipe(
      map(res => {
        this.authService.authChange$.next(true);
        return res;
      })
    );
  }
}
